<template>
  <div>
    <oto-page-title title="Ride App" class="inline-block mb-6" />
    <div class="border mb-4 rounded-lg border-gray-400">
      <div class="flex justify-between px-6 py-4 font-bold text-md">
        <div class="left w-3/4">
          <h4 class="text-gray-700 mb-3">Sign Up/Log In OTP</h4>
          <p class="text-sm text-gray-500 font-normal">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Autem
            necessitatibus soluta ratione in, eos commodi explicabo ullam
            quaerat facilis culpa.
          </p>
        </div>
        <div class="right w-1/4">
          <div
            class="ml-auto border rounded-md border-gray-400 w-8 h-8 flex items-center justify-center cursor-pointer"
          >
            <i class="fas fa-chevron-down text-sm text-gray-600"></i>
          </div>
        </div>
      </div>
      <div class="border-t border-gray-300 px-6 py-4">
        <div class="grid grid-cols-5 mb-2">
          <label class="text-gray-800 font-bold text-md">
            Choose Payment Gateway
          </label>
          <div class="col-span-4">
            <select
              name="paymentGateway"
              id="paymentGateway"
              class="w-5/6 px-3 bg-white text-md text-gray-600 h-10 border border-gray-400 rounded outline-none focus:border focus:border-black"
            >
              <option value="1">Test</option>
              <option value="2">Test2</option>
              <option value="3">Test3</option>
            </select>
            <div class="whitespace-normal w-5/6 py-4">
              <p class="text-gray-500 text-sm">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Doloribus, culpa! Optio at amet perferendis corrupti aliquid
                architecto repudiandae omnis ad.
              </p>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-5 mb-6">
          <label class="text-gray-800 font-bold text-md">
            Account SID
          </label>
          <div class="col-span-4">
            <input
              type="text"
              class="w-5/6 px-3 bg-white text-md text-gray-600 h-10 border border-gray-500 rounded focus:border focus:border-black"
            />
          </div>
        </div>
        <div class="grid grid-cols-5 mb-6">
          <label class="text-gray-800 font-bold text-md">
            Account SID
          </label>
          <div class="col-span-4">
            <input
              type="text"
              class="w-5/6 px-3 bg-white text-md text-gray-600 h-10 border border-gray-500 rounded focus:border focus:border-black outline-none"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="border mb-2 rounded-lg border-gray-400 px-6 py-4 font-bold text-md flex justify-between"
    >
      <div class="left w-3/4">
        <h4 class="text-gray-700 mb-3">Home</h4>
        <p class="text-sm text-gray-500 font-normal">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Autem
          necessitatibus soluta ratione in, eos commodi explicabo ullam quaerat
          facilis culpa.
        </p>
      </div>
      <div class="right w-1/4">
        <div
          class="ml-auto border rounded-md border-gray-400 w-8 h-8 flex items-center justify-center cursor-pointer"
        >
          <i class="fas fa-chevron-down text-sm text-gray-600"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue'
// import vSelect from 'vue-select'

// Vue.component('v-select', vSelect)

export default {
  name: 'RideSettings',
}
</script>

<style>
/* @import "vue-select/src/scss/vue-select.scss"; */
</style>
